.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.grid-element {
  flex: 0 0 calc(25% - 20px);
  padding: 20px;
  margin: 10px;
}
.person-image {
  width: 17rem;
  height: 17rem;
  object-fit: cover;
  border-radius: 360px;
  object-position: center;
}

.h2 {
  font-size: 3em;
  font-weight: 400;
}
.h3 {
  font-size: 1.7em;
  font-weight: normal;
}
.h4{
  font-size: 1.5em;
  font-weight: normal;
}
.text {
  font-size: 1em;
}

.link-raw {
  color: #000;
  text-decoration: none;
}
.link-raw-u {
  color: #000;
}

@media only screen and (max-width: 425px) {
  .grid {
    display: block;
  }
  .h2 {
    font-size: 2.5em;
  }
}

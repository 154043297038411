@font-face {
  font-family: "Glacial Indifference";
  src: url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.eot");
  src: url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.eot")
      format("embedded-opentype"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.woff2")
      format("woff2"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.woff")
      format("woff"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.ttf")
      format("truetype"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Regular.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Glacial Indifference";
  src: url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.eot");
  src: url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.eot")
      format("embedded-opentype"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.woff2")
      format("woff2"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.woff")
      format("woff"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.ttf")
      format("truetype"),
    url("./resources/Fonts/GlacialIndifference/GlacialIndifference-Bold.otf")
      format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body,
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.5;
  font-family: "Glacial Indifference", "Montserrat", sans-serif;
}
body {
  overflow-x: hidden;
  background-color: #f9f9f8;
}

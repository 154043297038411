.navbar {
  height: 8vh;
  width: 100%;
  background-color: #f9f9f8;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  transition: 0.4s all;
  border-bottom: 0.1px solid #f3f3f3;
}
.logo {
  height: 10vh;
  width: auto;
  margin-left: 4%;
}
.social-svg {
  width: 48px;
  height: 48px;
  margin-left: 2rem;
  color: #000;
  margin-top: 2vh;
}

.social-svg:active {
  color: #000;
}
.nav-items {
  width: 100%;
  margin-left: 40%;
}
.nav-item {
  color: #484848;
  text-decoration: none;
  font-size: 1em;
  margin-right: 4%;
  font-weight: bold;
}

.hamburger,
.navbar-links,
.logo-simple {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .hamburger,
  .navbar-links,
  .logo-simple {
    display: block;
  }
  .nav-items,
  .logo-simple {
    display: none;
  }
  .logo {
    margin-top: 0.5vh;
    width: 62%;
    height: auto;
    z-index: 10;
    margin-left: 0;
  }
  .hamburger {
    position: relative;
    z-index: 10;
    top: 1%;
    background-color: transparent;
    border: none;
    height: 60px;
    width: 70px;
  }
  .hamburger:hover {
    cursor: pointer;
  }
  .line {
    width: 3rem;
    height: 0.4rem;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    background-color: #4dc0d4;
  }
  .line::before,
  .line::after {
    content: "";
    position: absolute;
    width: 3rem;
    height: 0.4rem;
    border-radius: 20px;
    background-color: #4dc0d4;
    transition: all 0.5s ease-in-out;
    left: 0;
  }
  .line::before {
    transform: translateY(-12px);
  }
  .line::after {
    transform: translateY(12px);
  }
  .x-line {
    transform: translateX(50px);
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    z-index: 10;
    position: fixed;
  }
  .x-line::before {
    transform: rotate(45deg) translate(-35px, 35px);
    background-color: #000;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }
  .x-line::after {
    transform: rotate(-45deg) translate(-35px, -35px);
    background-color: #000;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }
  .navbar-links {
    background-color: #ffffff;
    position: fixed;
    left: 0;
    z-index: 5;
    top: 0;
    width: 100%;
    transform: translateY(0);
    height: 110vh;
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    padding-left: 20%;
    padding-top: 15vh;
    opacity: 1;
    transition: all 0.3s ease-out;
  }
  .hamburger-link {
    margin-top: 5vh;
  }
  .closeNav {
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in;
    z-index: 1;
  }

  .social-svg {
    display: none;
  }
}
